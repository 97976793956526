import { FieldArray } from 'formik';
import React from 'react';
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';

import { Expandable, Label, Option } from '../../../../common/expandable';
import { Checkbox } from '../../../../common/form/checkbox/checkbox';

export const Filter = ({
  bem,
  name,
  label,
  values,
  options,
  isOpen,
}) => (
  <div {...bem('filter')}>
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <Expandable
          label={label}
          role="menuitem"
          isOpen={isOpen}
          data-id={name}
        >
          <Label>
            {(values.length > 0) ? `${label}: ` : label}
            {(values.length > 0) && <em {...bem('amount')}>{values.length}</em>}
          </Label>

          {options.map(({ slug, title }) => (
            <Option key={slug} role="menuitemcheckbox" aria-checked={values.includes(slug)}>
              <Checkbox
                id={`needs-${slug}`}
                name={name}
                value={slug}
                checked={values.includes(slug)}
                onChange={(e) => (e.target.checked ? push(slug) : remove(values.indexOf(slug)))}
                {...bem('checkbox')}
              >
                {title}
              </Checkbox>
            </Option>
          ))}
        </Expandable>
      )}
    />
  </div>
);

Filter.propTypes = {
  bem: func.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  values: arrayOf(string).isRequired,
  options: arrayOf(shape({
    slug: string.isRequired,
    title: string.isRequired,
  })).isRequired,
  isOpen: bool,
};

Filter.defaultProps = {
  isOpen: false,
};
