import { useStaticQuery, graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { resolve } from '../../../../helpers/urls';
import { Content } from '../../../common/content';
import { TeaserProduct } from '../../../common/product';
import { Filter } from './components/filter';
import './filter.scss';

const bem = new BEMHelper({
  name: 'content-productfilter',
});

// const keys = {
//   esc: 27,
//   up: 38,
//   right: 39,
//   down: 40,
//   left: 37,
//   tab: 9,
//   pageUp: 33,
//   pageDown: 34,
//   space: 32,
//   enter: 13,
// };

const query = graphql`
  query ProductFilter {
    response: craftgql {
      products: entries(section: "products") {
        ...on CraftGQL_products_product_Entry {
          slug
          title

          need: productNeed(limit: 1) {
            ...on CraftGQL_needs_needs_Entry {
              slug
              title
            }
          }

          materials: productMaterials {
            ...on CraftGQL_materials_materials_Entry {
              slug
              title
            }
          }

          formats: productFormat {
            ...on CraftGQL_formats_formats_Entry {
              slug
              title
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
              label: variantLabel
              image: variantImage(limit: 1) {
                ...CommonProductTeaserFragment
              }
            }
          }

          department: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              id
              slug
            }
          }
        }
      }
    }
  }
`;

const filter = (target, items, property) => {
  items.forEach((item) => {
    const found = target.find((it) => it[property] === item[property]);

    if (!found) {
      target.push(item);
    }
  });

  return target;
};

const useFilter = (products, initialFilters) => {
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [activeFilters, setActiveFilters] = useState(initialFilters);

  useEffect(() => {
    const createFilter = (keyInFilters, keyInProduct) => (product) => {
      if (activeFilters[keyInFilters].length === 0) {
        return true;
      }

      let found = false;
      activeFilters[keyInFilters].forEach((value) => {
        product[keyInProduct].forEach(({ slug }) => {
          found = found || (value === slug);
        });
      });

      return found;
    };

    const newlyFilteredProducts = products
      .filter(createFilter('needs', 'need'))
      .filter(createFilter('materials', 'materials'))
      .filter(createFilter('formats', 'formats'));

    setFilteredProducts(newlyFilteredProducts);
  }, [activeFilters]);

  return { filteredProducts, setActiveFilters };
};

export const Productfilter = ({ department: [department] }) => {
  const menubar = useRef(null);
  // const isInitialMount = useRef(true);
  // const [count, setCount] = useState(0);
  // const [menuItems, setMenuItems] = useState([]);
  // const [openMenuItem, setOpenMenuItem] = useState(false);
  const { t } = useTranslation();
  const { id } = department;
  // @FIXME: This is ugly. We should move the filtering into the query, but
  // useStaticQuery doesn't support variables for now...
  const { response } = useStaticQuery(query);
  const products = response.products.filter((product) => product.department[0].id === id);
  // Build available filter options (unique entries):
  const needs = useMemo(() => products.reduce((acc, { need: n }) => filter(acc, n, 'slug'), []), []);
  const materials = useMemo(() => products.reduce((acc, { materials: m }) => filter(acc, m, 'slug'), []), []);
  const formats = useMemo(() => products.reduce((acc, { formats: f }) => filter(acc, f, 'slug'), []), []);

  const { filteredProducts, setActiveFilters } = useFilter(
    products,
    { needs: [], materials: [], formats: [] },
  );

  // useEffect(() => {
  //   // select all menu items
  //   const selectMenuItems = menubar.current.querySelectorAll('[role="menuitem"]');
  //   setMenuItems(selectMenuItems);
  // }, []);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else if (count >= 0) {
  //     menuItems[count].focus();
  //     // current active menu item should be tabbable
  //     menuItems.forEach((menuItem, i) => {
  //       if (i === count) {
  //         menuItem.tabIndex = 0;
  //       } else {
  //         menuItem.tabIndex = -1;
  //       }
  //     });
  //     if (openMenuItem) {
  //       setOpenMenuItem(menuItems[count].dataset.id);
  //     }
  //   }
  // }, [count]);

  // const onOptionYNav = (e, direction) => {
  //   if (direction === 'left') {
  //     // Set focus to the previous menu item.
  //     // If it's the first menu item, select the last menu item.
  //     if (count === 0) {
  //       setCount(menuItems.length - 1);
  //     } else {
  //       setCount((previousCount) => previousCount - 1);
  //     }
  //   } else if (direction === 'right') {
  //     // Focus the next menu item.
  //     // If it’s the last menu item, select the first menu item.
  //     if (count === (menuItems.length - 1)) {
  //       setCount(0);
  //     } else {
  //       setCount((previousCount) => previousCount + 1);
  //     }
  //   }
  //   e.preventDefault();
  // };

  // const toggleMenuItemOpen = () => {
  //   if (openMenuItem) {
  //     setOpenMenuItem(false);
  //   } else {
  //     setOpenMenuItem(menuItems[count].dataset.id);
  //   }
  // };

  // const onKeyDown = useCallback((e) => {
  //   switch (e.keyCode) {
  //     case keys.right:
  //       onOptionYNav(e, 'right');
  //       break;
  //     case keys.left:
  //       onOptionYNav(e, 'left');
  //       break;
  //     case keys.down:
  //       setOpenMenuItem(menuItems[count].dataset.id);
  //       break;
  //     case keys.pageUp:
  //       setCount(0);
  //       e.preventDefault();
  //       break;
  //     case keys.pageDown:
  //       setCount(menuItems.length - 1);
  //       e.preventDefault();
  //       break;
  //     case keys.up:
  //       toggleMenuItemOpen(e);
  //       break;
  //     case keys.esc:
  //       setOpenMenuItem(false);
  //       menuItems[count].focus();
  //       break;
  //     case keys.tab:
  //       setOpenMenuItem(false);
  //       break;
  //     case keys.space:
  //       toggleMenuItemOpen(e);
  //       break;
  //     case keys.enter:
  //       toggleMenuItemOpen(e);
  //       break;
  //     default:
  //       break;
  //   }
  // });

  return (
    <Content {...bem()}>
      <Formik
        enableReinitialize
        initialValues={{ needs: [], materials: [], formats: [] }}
      >
        {({
          values,
          resetForm,
        }) => {
          useEffect(() => {
            setActiveFilters(values);
          }, [values]);
          return (
            <Form>
              <div
                {...bem('filters')}
                role="menubar"
                aria-label={t('Filter products')}
                // onKeyDown={onKeyDown}
                ref={menubar}
              >
                <Filter
                  bem={bem}
                  name="needs"
                  label={t('Skin Needs')}
                  values={values.needs}
                  options={needs}
                  // isOpen={openMenuItem === 'needs'}
                />

                <Filter
                  bem={bem}
                  name="materials"
                  label={t('Active Biomaterial')}
                  values={values.materials}
                  options={materials}
                  // isOpen={openMenuItem === 'materials'}
                />

                <Filter
                  bem={bem}
                  name="formats"
                  label={t('Format')}
                  values={values.formats}
                  options={formats}
                  // isOpen={openMenuItem === 'formats'}
                />

                <div {...bem('reset')}>
                  <button
                    type="button"
                    role="menuitem"
                    // tabIndex="-1"
                    title={t('Click here to reset the filters.')}
                    onClick={() => resetForm()}
                    // data-id="reset"
                    {...bem('reset-button')}
                  >
                    {t('Reset filters')}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div aria-live="polite">
        <div role="status" {...bem('status')}>
          <p {...bem('status-text')}>
            {t('{{ count }} products', {
              count: filteredProducts.length,
              context: `${filteredProducts.length}`,
            })}
          </p>
        </div>

        <ul {...bem('list')}>
          {filteredProducts.map(({
            slug,
            title,
            need: [need],
            variant: [variant],
            department: [dep],
          }) => {
            // References to variant or department might not exist (depending on
            // published state for example). In this case do not render the teaser:
            if (!variant || !department) {
              return null;
            }

            const link = resolve('products:detail', {
              productSlug: slug,
              departmentSlug: dep.slug,
              variantSlug: variant.slug,
            });
            return (
              <li {...bem('list-item')} key={slug}>
                <TeaserProduct {...bem('teaser')} title={title} need={need.title} image={variant.image[0]} link={link} />
              </li>
            );
          })}
        </ul>
      </div>
    </Content>
  );
};

Productfilter.propTypes = {
  department: arrayOf(shape({
    id: string.isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment ProductfilterContentFragment on CraftGQL_pageContents_productFilter_BlockType {
    department(limit: 1) {
      ...on CraftGQL_departments_department_Entry {
        id
      }
    }
  }
`;
